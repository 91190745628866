
// <div>          
// <img src="https://via.placeholder.com/150" alt="Step 1" style="width:100%; border-radius:8px;" />

import { PayTourBody } from "@src/pages/pay/component/tour/payPage/payTour"
import { renderToStaticMarkup } from "react-dom/server";

// </div>
export const tourStepsConfig: Record<string, any> = {
    "teams/:id/pay": [
    {
      element: '#review-summary',
      // title: 'Review Summary',
      description: renderToStaticMarkup(<PayTourBody/>),
    },
    {
      element: '#submit-bill',
      // title: 'Submit Bill',
      description: `<p>Click to submit your bill.</p>`,
    },
  ],
    "teams/:id/bills/:id/edit": [
        {
            element: '#bill-vendor-details-input',
            title: 'Adding vendor details',
            description: `<p>First add vendor to your bill and then</p>
           
          `,
        },
        {
            element: '#bill-details-input',
            title: 'Creating Invoices',
            description: `
          
            <div style={{width: '220px'}}>
              <p>Rollover to the top of a column for option like:</p>
              <ul>
                <li>Add your business details.</li>
                <li>It will be used as a default  details  in the invoice.</li>
              </ul>  
            </div>
          `,
        },
        {
            element: '#bill-subject-input',
            title: 'Adding subject',
            description: `
            <p>Add subject to your bill</p>
          `,
        },
        {
            element: '#bill-line-item-details-input',
            title: 'Add Inline items',
            description: `
            <div style={{width: '220px'}}>
              <p>Rollover to the top of a column for option like:</p>
              <ul>
                <li>Add your business details.</li>
                <li>It will be used as a default  details  in the invoice.</li>
              </ul>  
            </div>
          `,
        },
        {
            element: '#bill-file-under',
            title: 'Select filed under',
            description: `
            <div>
              <p>Rollover to the top of a column for option like:</p>
              <ul>
                <li>Add your business details.</li>
                <li>It will be used as a default  details  in the invoice.</li>
              </ul>
            </div>
          `,
        },
        {
            element: '#bill-image',
            title: 'Add bill image',
            description: `
            <div>
              <p>Rollover to the top of a column for option like:</p>
              <ul>
                <li>Add your business details.</li>
                <li>It will be used as a default  details  in the invoice.</li>
              </ul>
            </div>
          `,
        },
        {
            element: '#flute-support-button',
            title: 'Customer details',
            description: `
            <div>
              <p></p>Go here to:</p>
              <ul>
                <li>Take a tour again.</li>
                <li>View shortcuts.</li>
              </ul>
            </div>
          `,
        },
    ],
};
