import { PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import { toggleColorMode } from '@src/features/theme/themeSlice';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MainLayoutProps } from './mainLayoutInterface';
import { MainLayoutWrapper, PageContainer } from './mainLayoutStyles';
import { PrimarySideBar } from './primarySidebar/primarySidebar';
import SecondarySidebar from './secondarySidebar/secondarySidebar';
import { setSideBar } from '@src/features/sideBar/sideBarSlice';

type ModelType = {
  theme: {
    mode: PaletteMode | undefined;
  };
};
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const MainLayout = ({ children }: MainLayoutProps) => {
  const dispatch = useDispatch();
  const mode = useSelector((state: ModelType) => state.theme.mode);
  const themes = useSelector((state: AppState) => state.theme.theme);
  const isSideCollapse = useSelector((state: AppState) => state.sideBar.collapsed);
  const { id } = useParams();

  const handleToggleColorMode = () => {
    dispatch(toggleColorMode());
  };

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode,
    }),
    []
  );

  const navbarRef = useRef<HTMLDivElement>(null); // reference to common popup
  const topbarRef = useRef<HTMLDivElement>(null); // reference to common popup

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  // localStorage.setItem('Theme', theme.palette.mode);
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth < 577);
  const [isTabView, setIsTabView] = useState<boolean>(
    window.innerWidth > 600 && window.innerWidth <= 1024
  );
  const location = useLocation();
  useEffect(() => {
    if (typeof isSideCollapse == 'boolean') {
      setCollapse(isSideCollapse);
    }
  }, [isSideCollapse]);
  // Add an event listener to check and update the mobile view state
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 577);
      setIsTabView(window.innerWidth > 600 && window.innerWidth < 901);
      if (window.innerWidth < 768) {
        //  dispatch(setSideBar(false));
        // setCollapsed(true);
        showSideBar(false);
      } else {
        setCollapsed(false);
        showSideBar(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      dispatch(setSideBar(window.innerWidth < 768));
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial state
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);
  
  const [collapsed, setCollapsed] = React.useState<null | boolean>(isMobileView ? true : false);
  const [sidebar, showSideBar] = React.useState<null | boolean>(isMobileView ? false : true);
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        navbarRef &&
        navbarRef.current &&
        !navbarRef.current.contains(e.target as Node) &&
        topbarRef &&
        topbarRef.current &&
        !topbarRef.current.contains(e.target as Node) &&
        isMobileView &&
        !isTabView
      ) {
        showSideBar(!sidebar);
      }
    };

    if (sidebar) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [sidebar, showSideBar, isMobileView]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <MainLayoutWrapper theme={themes} collapsed={collapse} className='jayasurya'>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <PrimarySideBar collapse={collapse} setCollapse={setCollapse} />

          <PageContainer
            theme={themes}
            className={'page-container ' + `${collapsed ? 'body-trimmed' : ''} `}
          >
            {location.pathname.includes('settings') || location.pathname.includes('details') ? (
              <SecondarySidebar children={children} />
            ) : (
              <div className="main-content-div">{children}</div>
            )}
          </PageContainer>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </MainLayoutWrapper>
  );
};
