import { AppState } from '@src/app/rootReducer';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FltIcon from '../../fltIcon/fltIcon';
import { FltDynamicDropdownProps } from './FltDynamicDropdownInterface';
import { FltDynamicDropdownWrapper } from './FltDynamicDropdownStyle';
import { DropdownMenu } from './dropdownMenu';

export const FltDynamicDropdown: React.FC<FltDynamicDropdownProps> = ({
  // data,
  selectOptions,
  buttonComponent,
  // dialogPosition,
  // placement,
  // menuStyle,
  // menuItemsData,
  // anchorEl,
  // open,
  // onClose,
  // placement = 'bottom-start',
  // selectOptions,
  // type = 'normal',
  // preSelectedItems,
  // placeholder,
  // disableSearch = false,
  // onClick,
  // title,
  // info = 'emailId',
  // menuItemRenderer,
  // footerActionLabel,
  // onFooterActionClick,
  width,
  menuStyle,
  setSearchTerm,
  placement = 'bottom-start',
  menuHeight,
  ...rest
}) => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  // ActionButton
  const [actionBtnAnchorEl, setActionBtnAnchorEl] = useState<null | HTMLElement>(null);
  const [openActionBtn, setOpenActionBtn] = useState(false);
  const [calculatedMenuStyle, setCalculatedMenuStyle] = useState(menuStyle || {});
  const buttonRef = useRef<HTMLDivElement>(null);
  const handleActionBtnClose = () => {
    setOpenActionBtn(false);
    setActionBtnAnchorEl(null);
  };
  const handleActionBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenActionBtn((prevOpen) => !prevOpen);
    setActionBtnAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (width === 'parent-width') {
      const updateWidth = () => {
        if (buttonRef.current) {
          const buttonWidth = buttonRef.current.offsetWidth;
          setCalculatedMenuStyle((prevStyle) => ({
            ...prevStyle,
            width: `${buttonWidth}px`,
          }));
        }
      };

      updateWidth();

      // Attach resize listener for dynamic resizing
      window.addEventListener('resize', updateWidth);
      return () => {
        window.removeEventListener('resize', updateWidth);
      };
    }else{
      if(menuStyle){
        setCalculatedMenuStyle(menuStyle);
      }
    }
  }, [width, menuStyle]);

  // Render the buttonComponent if provided, otherwise render the default button
  const renderButton = buttonComponent ? (
    React.cloneElement(buttonComponent, {
      onClick: handleActionBtnClick,
      ref: buttonRef as React.Ref<any>,
      styles: {
        ...(buttonComponent.props.styles || {}), // Preserve existing styles
        backgroundColor: theme.surface.background.secondary, // Add or override styles
      },
    })
  ) : (
    <div ref={buttonRef}>
      <FltIcon weight="thin" name="More" size={16} onClick={handleActionBtnClick} />
    </div>
  );

  return (
    <FltDynamicDropdownWrapper theme={theme}>
      {renderButton}
      <DropdownMenu
        anchorEl={actionBtnAnchorEl}
        open={openActionBtn}
        setOpen={setOpenActionBtn}
        onClose={handleActionBtnClose}
        // footerActionLabel={footerActionLabel}
        // onFooterActionClick={onFooterActionClick}
        menuStyle={calculatedMenuStyle}
        selectOptions={selectOptions}
        setSearchTerm={setSearchTerm}
        placement={placement}
        menuHeight={menuHeight}
        {...rest}
      />
    </FltDynamicDropdownWrapper>
  );
};
