import { AppState } from '@src/app/rootReducer';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useSelector } from 'react-redux';
import FltIcon from '../../fltIcon/fltIcon';
import FltText from '../../fltText/fltText';
import { TextInputWrapper } from './fltTextInputStyles';
import { FltTextInputProps } from './fltTextInputTypes';
export const FltTextInput = forwardRef<HTMLInputElement, FltTextInputProps>(
  (
    {
      id,
      name,
      size = 'default',
      inputType = 'text',
      label,
      errorText,
      placeholder,
      onChange,
      value,
      setValue,
      disabled,
      onKeyDown,
      onClick,
      maxLength,
      rightGlyph,
      leftGlyph,
      width,
      minWidth,
      maxWidth,
      bold,
      desc,
      styles,
      appearance = 'border',
      onBlur,
      inputStyles,
      autoComplete,
      autoFocus,
      onFocus,
      required = false,
    }: FltTextInputProps,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const theme = useSelector((data: AppState) => data.theme.theme);

    const colorPickerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      // Handle outside clicks and Enter key press
      function handleClickOrKeyPress(event: any) {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
          setOpen(false); // Close on outside click
        } else if (event.key === 'Enter') {
          setOpen(false); // Close on Enter key press
        }
      }

      document.addEventListener('mousedown', handleClickOrKeyPress);
      document.addEventListener('keydown', handleClickOrKeyPress);

      return () => {
        document.removeEventListener('mousedown', handleClickOrKeyPress);
        document.removeEventListener('keydown', handleClickOrKeyPress);
      };
    }, []);

    return (
      <TextInputWrapper
        size={size}
        theme={theme}
        minWidth={minWidth}
        maxWidth={maxWidth}
        appearance={appearance}
        width={width}
        glyphs={
          rightGlyph && leftGlyph ? 'both' : rightGlyph ? 'right' : leftGlyph ? 'left' : 'none'
        }
        bold={bold}
        styles={styles}
        inputStyles={inputStyles}
      >
        {label && (
          <FltText variant="heading-small">
            {label}{' '}
            {required && (
              <FltText variant="heading-small" textColor="content-error">
                *
              </FltText>
            )}
          </FltText>
        )}
        <div className="input-area">
          {leftGlyph && (
            <div
              className="icon-div"
              style={{
                ...(leftGlyph && { left: '0' }),
              }}
            >
              {leftGlyph}
            </div>
          )}
          {appearance == 'color-picker' && (
            <div
              className="color-picker-div"
              onClick={() => setOpen(!open)}
              ref={colorPickerRef}
              style={{ background: `${value}` }}
            >
              {open && (
                <div onClick={(e) => e.stopPropagation()}>
                  <HexColorPicker color={value} onChange={(newColor) => setValue(newColor)} />
                </div>
              )}
            </div>
          )}

          <input
            id={id}
            name={name}
            className={`${errorText ? 'error' : ''}`}
            onFocus={onFocus}
            type={
              inputType === 'email'
                ? 'email'
                : inputType === 'text'
                  ? 'text'
                  : inputType === 'number'
                    ? 'number'
                    : inputType === 'date'
                      ? 'date'
                      : showPassword
                        ? 'text'
                        : 'password'
            }
            ref={ref}
            placeholder={placeholder ? placeholder : ''}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            onClick={onClick}
            maxLength={maxLength}
            value={value !== undefined ? value : ''}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
          />
          {inputType == 'password' && (
            <span className="icon-container" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <FltIcon name="Eye" size={14}></FltIcon>
              ) : (
                <FltIcon name="EyeClosed" size={14}></FltIcon>
              )}
            </span>
          )}
          {rightGlyph && (
            <div
              className="icon-div"
              style={{
                ...(rightGlyph && { right: '0' }),
              }}
            >
              {rightGlyph}
            </div>
          )}
        </div>
        {errorText && <span className="error-message">{errorText}</span>}
        {desc && (
          <FltText variant="heading-small" textColor="content-static">
            {desc}
          </FltText>
        )}
      </TextInputWrapper>
    );
  }
);
