import Dexie, { Table } from 'dexie';
import 'dexie-observable';
import 'dexie-syncable';
import { SYNC_URL } from '../config';
import './socketSyncProtocol.js';

// Importing entity classes
import store from '@src/app/store';
import { setAddresses } from '@src/features/entity/addresses/addressesSlice';
import { setDocumentAddress } from '@src/features/entity/documentAddress/documentAddressSlice';
import { setDocumentLineItems } from '@src/features/entity/documentLineItems/documentLineItemsSlice';
import { setDocumentLineItemTaxes } from '@src/features/entity/documentLineItemTaxes/documentLineItemTaxesSlice';
import { setDocuments } from '@src/features/entity/documents/documentsSlice';
import { setEmails } from '@src/features/entity/emails/emailsSlice';
import { setEventSubscriptions } from '@src/features/entity/eventSubscriptions/eventSubscriptionsSlice';
import { setGroups } from '@src/features/entity/groups/groupsSlice';
import { setInitiatives } from '@src/features/entity/initiatives/initiativesSlice';
import { setParties } from '@src/features/entity/parties/partiesSlice';
import { setPayments } from '@src/features/entity/payments/paymentsSlice';
import { setPeriods } from '@src/features/entity/periods/periodsSlice';
import { setPhoneNumbers } from '@src/features/entity/phoneNumbers/phoneNumbersSlice';
import { setPreference } from '@src/features/entity/preference/preferenceSlice';
import { setProcessedEventMessage } from '@src/features/entity/processedEventMessages/processedEventMessageSlice';
import { setProducts } from '@src/features/entity/products/productsSlice';
import { setProductTaxes } from '@src/features/entity/productTaxes/productTaxesSlice';
import { setPrograms } from '@src/features/entity/programs/programsSlice';
import { setPublishedEvents } from '@src/features/entity/publishedEvents/publishedEventsSlice';
import { setTaxes } from '@src/features/entity/taxes/taxesSlice';
import { setTeams } from '@src/features/entity/teams/teamsSlice';
import { setUserRelship } from '@src/features/entity/userRelship/userRelshipSlice';
import { setUsers } from '@src/features/entity/users/usersSlice';
import { setWorkObjects } from '@src/features/entity/workObjects/workObjectsSlice';
import { Account } from './tables/account.entity';
import { Address } from './tables/address.entity.js';
import { Approvals } from './tables/approvals.entity';
import { Discount } from './tables/discount.entity';
import { DocumentAddress } from './tables/documentAddress.entity';
import { DocumentLineItem } from './tables/documentLineItem.entity';
import { DocumentLineItemTax } from './tables/documentLineItemTax.entity';
import { Document } from './tables/documents.entity';
import { Email } from './tables/email.entity.js';
import { Events } from './tables/events.entity';
import { EventSubscriptions } from './tables/eventSubscriptions.entity';
import { EventTemplate } from './tables/eventTemplate.entity';
import { Groups } from './tables/groups.entity';
import { InviteRelship } from './tables/inviteRelship.entity';
import { Ledger } from './tables/ledger.entity';
import { NotificationLogs } from './tables/notification_logs.entity.js';
import { Party } from './tables/parties.entity';
import { Payment } from './tables/payment.entity.js';
import { Periods } from './tables/period.entity';
import { PhoneNumber } from './tables/phoneNumbers.entity.js';
import { Positions } from './tables/positions.entity';
import { Preference } from './tables/preference.entity';
import { ProcessedEventMessage } from './tables/processedEventMessage.entity';
import { Product } from './tables/product.entity.js';
import { ProductTaxes } from './tables/productTax.entity';
import { PublishedEvent } from './tables/publishedEvents.entity';
import { ScheduledNotifications } from './tables/scheduled-notifications.entity';
import { Shipping } from './tables/shipping.entity';
import { SubLedger } from './tables/subLedger.entity.js';
import { SubLedgerEntry } from './tables/subLedgerEntry.entity';
import { Tax } from './tables/tax.entity.js';
import { User } from './tables/user.entity.js';
import { UserLoginProfile } from './tables/userLoginProfile.entity';
import { UserRelships } from './tables/userRelships.entity.js';
import { WorkObject } from './tables/workObjects.entity';
import { setAccounts } from '@src/features/entity/accounts/accountSlice';

// Define the main AppDB class extending Dexie
export class AppDB extends Dexie {
  // Declare tables
  accounts!: Table<Account, number>;
  documents!: Table<Document, number>;
  document_line_items!: Table<DocumentLineItem, number>;
  document_line_item_taxes!: Table<DocumentLineItemTax, number>;
  scheduled_notifications!: Table<ScheduledNotifications, number>;
  notification_logs!: Table<NotificationLogs, number>;
  users!: Table<User, number>;
  payments!: Table<Payment, number>;
  addresses!: Table<Address, number>;
  products!: Table<Product, number>;
  preferences!: Table<Preference, number>;
  shipping!: Table<Shipping, number>;
  taxes!: Table<Tax, number>;
  ledgers!: Table<Ledger, number>;
  discount!: Table<Discount, number>;
  sub_ledger!: Table<SubLedger, number>;
  subledger_entry!: Table<SubLedgerEntry, number>;
  emails!: Table<Email, number>;
  phone_numbers!: Table<PhoneNumber, number>;
  user_relships!: Table<UserRelships, number>;
  groups!: Table<Groups, number>;
  positions!: Table<Positions, number>;
  periods!: Table<Periods, number>;
  approvals!: Table<Approvals, number>;
  processed_event_messages!: Table<ProcessedEventMessage, number>;
  events!: Table<Events, number>;
  published_events!: Table<PublishedEvent, number>;
  event_templates!: Table<EventTemplate, number>;
  history!: Table<History, number>;
  event_subscriptions!: Table<EventSubscriptions, number>;
  user_login_profiles!: Table<UserLoginProfile, number>;
  work_objects!: Table<WorkObject, number>;
  parties!: Table<Party, number>;
  invite_relships!: Table<InviteRelship, number>;
  document_address!: Table<DocumentAddress, number>;
  product_taxes!: Table<ProductTaxes, number>;

  // Add isDataLoaded property
  isDataLoaded: boolean = false;

  constructor() {
    // Call Dexie constructor with database name
    super('flute');

    // Define version 17 schema
    this.version(21).stores({
      users: '$$oid, id',
      user_privileges: '$$oid, id',
      privileges: '$$oid, id',
      addresses: '$$oid, id',
      emails: '$$oid, id',
      phone_numbers: '$$oid, id',
      accounts: `$$oid, id`,
      documents: `$$oid, id`,
      products: '$$oid, id',
      taxes: '$$oid, id',
      document_line_items: '$$oid, id',
      document_line_item_taxes: '$$oid, id',
      products_taxes_taxes: '$$oid',
      product_taxes: '$$oid',
      ledger: '$$oid',
      sub_ledger: '$$oid,id',
      sub_ledger_entry: '$$oid',
      payment: '$$oid, id, paymentBYId',
      notification_logs: '$$oid, [entityType+entityId]',
      scheduled_notifications: '$$oid, id, [entityType+entityId]',
      devices: '$$oid',
      preferences: '$$oid',
      discounts: '$$oid',
      shipping: '$$oid',
      payments: '$$oid',
      user_relships: '$$oid',
      groups: '$$oid,id',
      positions: '$$oid,id',
      periods: '$$oid,id',
      approvals: '$$oid,id',
      processed_event_messages: '$$oid, id',
      events: '$$oid,id',
      published_events: '$$oid,id',
      event_templates: '$$oid,id',
      history: '$$oid,id',
      event_subscriptions: '$$oid,id',
      user_login_profiles: '$$oid,id',
      work_objects: '$$oid,id',
      parties: '$$oid,id',
      invite_relships: '$$oid,id',
      document_address: '$$oid,id',
    });

    // Define version 2 schema (empty in this case)
    this.version(2).stores({});

    // Initialize synchronization with the server
    this.syncServer();

    // Load initial data and then setup change listeners
    this.loadInitialData().then(() => {
      this.isDataLoaded = true;
      this.setupChangeListeners();
    });
  }

  async loadInitialData() {
    const [
      accounts,
      groups,
      preferences,
      periods,
      user_relships,
      users,
      processed_event_messages,
      published_events,
      event_subscriptions,
      document_line_items,
      document_line_item_taxes,
      documents,
      payments,
      parties,
      emails,
      addresses,
      phone_numbers,
      product_taxes,
      document_address,
      products,
      taxes,
      work_objects,
    ] = await Promise.all([
      this.accounts.toArray(),
      this.groups.toArray(),
      this.preferences.toArray(),
      this.periods.toArray(),
      this.user_relships.toArray(),
      this.users.toArray(),
      this.processed_event_messages.toArray(),
      this.published_events.toArray(),
      this.event_subscriptions.toArray(),
      this.document_line_items.toArray(),
      this.document_line_item_taxes.toArray(),
      this.documents.toArray(),
      this.payments.toArray(),
      this.parties.toArray(),
      this.emails.toArray(),
      this.addresses.toArray(),
      this.phone_numbers.toArray(),
      this.product_taxes.toArray(),
      this.document_address.toArray(),
      this.products.toArray(),
      this.taxes.toArray(),
      this.work_objects.toArray(),
    ]);

    const teams = groups.filter((group) => group.type === 'TEAM');
    const programs = groups.filter((group) => group.type === 'PROGRAM');
    const initiatives = groups.filter((group) => group.type === 'INITIATIVE');

    store.dispatch(setAccounts(accounts));
    store.dispatch(setPreference(preferences));
    store.dispatch(setPeriods(periods));
    store.dispatch(setUserRelship(user_relships));
    store.dispatch(setGroups(groups));
    store.dispatch(setTeams(teams));
    store.dispatch(setPrograms(programs));
    store.dispatch(setInitiatives(initiatives));
    store.dispatch(setUsers(users));
    store.dispatch(setProcessedEventMessage(processed_event_messages));
    store.dispatch(setPublishedEvents(published_events));
    store.dispatch(setEventSubscriptions(event_subscriptions));
    store.dispatch(setParties(parties));
    store.dispatch(setDocuments(documents));
    store.dispatch(setDocumentLineItems(document_line_items));
    store.dispatch(setDocumentLineItemTaxes(document_line_item_taxes));
    store.dispatch(setPayments(payments));
    store.dispatch(setEmails(emails));
    store.dispatch(setAddresses(addresses));
    store.dispatch(setPhoneNumbers(phone_numbers));
    store.dispatch(setDocumentAddress(document_address));
    store.dispatch(setProductTaxes(product_taxes));
    store.dispatch(setProducts(products));
    store.dispatch(setTaxes(taxes));
    store.dispatch(setWorkObjects(work_objects));


    console.log('redux is updating');
  }

  setupChangeListeners() {
    // Only run setupChangeListeners if the initial data has been loaded
    if (!this.isDataLoaded) {
      console.log('Change listeners will only be set up after initial data is loaded');
      return;
    }

    this.on('changes', async (changes) => {
      const fetchData = {
        preferences: false,
        periods: false,
        groups: false,
        user_relships: false,
        users: false,
        processed_event_messages: false,
        published_events: false,
        event_subscriptions: false,
        document_line_items: false,
        document_line_item_taxes: false,
        documents: false,
        payments: false,
        parties: false,
        emails: false,
        addresses: false,
        phone_numbers: false,
        document_address: false,
        product_taxes: false,
        products: false,
        taxes: false,
        work_objects: false,
        accounts: false
      };

      changes.forEach((change) => {
        switch (change.table) {
          case 'accounts':
            fetchData.accounts = true;
            break;
          case 'preferences':
            fetchData.preferences = true;
            break;
          case 'periods':
            fetchData.periods = true;
            break;
          case 'groups':
            fetchData.groups = true;
            break;
          case 'user_relships':
            fetchData.user_relships = true;
            break;
          case 'users':
            fetchData.users = true;
            break;
          case 'processed_event_messages':
            fetchData.processed_event_messages = true;
            break;
          case 'published_events':
            fetchData.published_events = true;
            break;
          case 'event_subscriptions':
            fetchData.event_subscriptions = true;
            break;
          case 'documents':
            fetchData.documents = true;
            break;
          case 'document_line_items':
            fetchData.document_line_items = true;
            break;
          case 'document_line_item_taxes':
            fetchData.document_line_item_taxes = true;
            break;
          case 'documents':
            fetchData.documents = true;
            break;
          case 'parties':
            fetchData.parties = true;
            break;
          case 'payments':
            fetchData.payments = true;
            break;
          case 'emails':
            fetchData.emails = true;
            break;
          case 'addresses':
            fetchData.addresses = true;
            break;
          case 'phone_numbers':
            fetchData.phone_numbers = true;
            break;
          case 'document_address':
            fetchData.document_address = true;
            break;
          case 'product_taxes':
            fetchData.product_taxes = true;
            break;
          case 'products':
            fetchData.products = true;
            break;
          case 'taxes':
            fetchData.taxes = true;
            break;
          case 'work_objects':
            fetchData.work_objects = true;
            break;
        }
      });

      if (fetchData.accounts) {
        const accounts = await this.accounts.toArray();
        store.dispatch(setAccounts(accounts));
      }

      if (fetchData.preferences) {
        const preferences = await this.preferences.toArray();
        store.dispatch(setPreference(preferences));
      }

      if (fetchData.periods) {
        const periods = await this.periods.toArray();
        store.dispatch(setPeriods(periods));
      }

      if (fetchData.groups) {
        const groups = await this.groups.toArray();
        const teams = groups.filter((group) => group.type === 'TEAM');
        const programs = groups.filter((group) => group.type === 'PROGRAM');
        const initiatives = groups.filter((group) => group.type === 'INITIATIVE');
        store.dispatch(setGroups(groups));
        store.dispatch(setTeams(teams));
        store.dispatch(setPrograms(programs));
        store.dispatch(setInitiatives(initiatives));
      }

      if (fetchData.user_relships) {
        const userRelships = await this.user_relships.toArray();
        store.dispatch(setUserRelship(userRelships));
      }

      if (fetchData.users) {
        const users = await this.users.toArray();
        store.dispatch(setUsers(users));
      }

      if (fetchData.processed_event_messages) {
        const processedEventMessages = await this.processed_event_messages.toArray();
        store.dispatch(setProcessedEventMessage(processedEventMessages));
      }

      if (fetchData.published_events) {
        const published_events = await this.published_events.toArray();
        store.dispatch(setPublishedEvents(published_events));
      }
      if (fetchData.event_subscriptions) {
        store.dispatch(setEventSubscriptions(await this.event_subscriptions.toArray()));
      }

      if (fetchData.documents) {
        const documents = await this.documents.toArray();
        store.dispatch(setDocuments(documents));
      }

      if (fetchData.document_line_items) {
        const document_line_items = await this.document_line_items.toArray();
        store.dispatch(setDocumentLineItems(document_line_items));
      }

      if (fetchData.document_line_item_taxes) {
        const document_line_item_taxes = await this.document_line_item_taxes.toArray();
        store.dispatch(setDocumentLineItemTaxes(document_line_item_taxes));
      }

      if (fetchData.payments) {
        const payments = await this.payments.toArray();
        store.dispatch(setPayments(payments));
      }
      if (fetchData.parties) {
        const parties = await this.parties.toArray();
        store.dispatch(setParties(parties));
      }

      if (fetchData.emails) {
        const emails = await this.emails.toArray();
        store.dispatch(setEmails(emails));
      }

      if (fetchData.phone_numbers) {
        const phoneNumbers = await this.phone_numbers.toArray();
        store.dispatch(setPhoneNumbers(phoneNumbers));
      }

      if (fetchData.addresses) {
        const addresses = await this.addresses.toArray();
        store.dispatch(setAddresses(addresses));
      }

      if (fetchData.document_address) {
        const documentAddress = await this.document_address.toArray();
        store.dispatch(setDocumentAddress(documentAddress));
      }

      if (fetchData.product_taxes) {
        const productTaxes = await this.product_taxes.toArray();
        store.dispatch(setProductTaxes(productTaxes));
      }

      if (fetchData.products) {
        const products = await this.products.toArray();
        store.dispatch(setProducts(products));
      }

      if (fetchData.taxes) {
        const taxes = await this.taxes.toArray();
        store.dispatch(setTaxes(taxes));
      }

      if (fetchData.work_objects) {
        const work_objects = await this.work_objects.toArray();
        store.dispatch(setWorkObjects(work_objects));
      }
    });
  }

  // Method to initialize synchronization with the server
  syncServer() {
    console.log('🚀 Start Sync Server');

    try {
      // 🔍 Get the authentication user string from localStorage
      const authUserString = window.localStorage.getItem('appState');

      if (!authUserString) {
        console.log('❌ No user session found. Skipping sync.');
        return;
      }

      // ✅ Extract the access token and decode it
      const accessToken = JSON.parse(authUserString).auth.accessToken;
      const decodedToken: {
        accountLoginProfileId: string;
        accountId: string;
        userLoginProfileId: string;
        userId: string;
      } = JSON.parse(atob(accessToken.split('.')[1]));
      const userLoginProfileId = JSON.parse(authUserString).auth.userLoginProfileId;

      // 🔌 Disconnect any existing sync connections before re-establishing
      console.log('🔌 Disconnecting previous sync connection...');
      this.syncable.disconnect('disconnected');

      // 🌐 Connect to sync server with access token
      const syncUrl = `${SYNC_URL}?token=${accessToken}&userLoginProfileId=${userLoginProfileId}&accountLoginProfileId=${decodedToken.accountLoginProfileId}`;
      console.log('🌐 Connecting to sync server at:', syncUrl);

      this.syncable.connect('websocket', syncUrl);
      
      // 📡 Listen for sync status changes
      this.syncable.on('statusChanged', function (newStatus) {
        const statusText = Dexie.Syncable.StatusTexts[newStatus];
        console.log('✅ Syncable connection established and listening for status changes.');
        console.log(`🔄 Sync Status Changed: ${statusText}`);
      });

     
    } catch (error) {
      // 🛑 Catch any errors that occur during the sync process
      console.error('❌ Error occurred during sync process:', error);
      console.trace();
    }
  }
}

// Log initialization
console.log('Init DB');

// Export an instance of the AppDB class
export const db = new AppDB();
