import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const billSlice = createSlice({
  name: 'bill',
  initialState: {
    name:  '',
    partyId: '',
    documentNumber:  '',
    orderNumber:'',
    billDate:'',dueDate:'',
    billAmount:'',
    billAmountCurrency:'',
    lineItemId:'',
     initiativeId: '',
    workObjectId: ''
  },
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setPartyId: (state, action) => {
      state.partyId = action.payload;
    },
    setDocumentNumber: (state, action) => {
      state.documentNumber
      = action.payload;
    },
    setOrderNumber:(state, action) => {
        state.orderNumber
        = action.payload;
      },
    setBillDate:(state, action) => {
        state.billDate
        = action.payload;
      },
      setDueDate:(state, action) => {
        state.dueDate
        = action.payload;
      },
      setBillAmount:(state, action) => {
        state.billAmount
        = action.payload;
      },
      setBillAmountCurrency:(state, action) => {
        state.billAmountCurrency
        = action.payload;
      },
      setLineItemId:(state, action) => {
        state.lineItemId
        = action.payload;
      },
      setInitiativeId:(state, action) => {
        state.initiativeId
        = action.payload;
      },
      setWorkObjectId:(state, action) => {
        state.workObjectId
        = action.payload;
      },

  },
});

export const { setName, setPartyId, setDocumentNumber,setOrderNumber,setBillDate ,setDueDate,setBillAmount,setLineItemId,setInitiativeId,setWorkObjectId,setBillAmountCurrency} = billSlice.actions;
export default billSlice.reducer;
