import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const PayPageTourBodyWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-2-5x, 20px);
  /* padding-top: 56px; */
  .svg-container-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    .svg-container {
      display: flex;
      width: 232px;
      padding: var(--spacing-2x, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-2x, 16px);
      box-shadow: 0px 1px 4px 0px rgba(212, 212, 212, 0.16);
      border-radius: var(--spacing-1-5x, 12px);
      border: 1px solid var(--Border-teritary, #eaebeb);
      .svg-text-content-container {
        display: flex;
        width: 232px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }

  .pay-tour-content-container {
    display: flex;
    padding-bottom: var(--spacing-2-5x, 20px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-1x, 8px);
    align-self: stretch;
  }
  .bullet-point-container{}
`;
