import SVG1 from '@src/assets/svgs/paytour1.svg';
import SVG2 from '@src/assets/svgs/paytour2.svg';
import SVG3 from '@src/assets/svgs/paytour3.svg';
import { PayPageTourBodyWrapper } from './payTourStyle';

export const PayTourBody = () => {
  return (
    <PayPageTourBodyWrapper>
      <div className="svg-container-wrapper">
        <div className="svg-container">
          <img src={SVG1} alt="No data illustration" width="100%" height="100px" loading="lazy" />
          <div className="svg-text-content-container">
            <span className="text-heading-medium text-content-primary">Generate Bills</span>
            <span className="text-body-medium text-content-tertiary">
              Quickly create detailed bills and keep your finances organized.
            </span>
            {/* <FltText variant="heading-medium" textColor="content-primary">
              Generate Bills
            </FltText>
            <FltText variant="heading-medium" textColor="content-primary">
              Quickly create detailed bills and keep your finances organized.
            </FltText> */}
          </div>
        </div>
        <div className="svg-container">
          <img src={SVG2} alt="No data illustration" width="100%" height="100px" loading="lazy" />
          <div className="svg-text-content-container">
            <span className="text-heading-medium text-content-primary">Monitor Payments</span>
            <span className="text-body-medium text-content-tertiary">
              Track the status of payments and ensure timely receipts.
            </span>
            {/* <FltText variant="heading-medium" textColor="content-primary">
              Monitor Payments
            </FltText>
            <FltText variant="heading-medium" textColor="content-primary">
              Track the status of payments and ensure timely receipts.
            </FltText> */}
          </div>
        </div>
        <div className="svg-container">
          <img src={SVG3} alt="No data illustration" width="100%" height="100px" loading="lazy" />
          <div className="svg-text-content-container">
            <span className="text-heading-medium text-content-primary">Integrate & Simplify</span>
            <span className="text-body-medium text-content-tertiary">
              Link bills to projects and manage payment records with ease.
            </span>
          </div>
        </div>
      </div>
      <div className="pay-tour-content-container">
        <span className="text-display-large text-content-primary">Integrate & Simplify</span>
        <div className='bullet-point-container'>
          <span className="text-display-large text-content-tertiary">• Create and send bills effortlessly in a few clicks.</span>
          <span className="text-display-large text-content-tertiary">• Stay on top of payment updates and balances.</span>
          <span className="text-display-large text-content-tertiary">• Optimize financial workflows for better efficiency.</span>
        </div>
      </div>
    </PayPageTourBodyWrapper>
  );
};
