import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

type SidebarState = {
  activeItem: string;
  visibleSections: { [key: string]: boolean };
  handleSetActiveItem: (item: string) => void;
  handleSectionToggle: (title: string) => void;
};

export const useSidebarState = (): SidebarState => {
  const [activeItem, setActiveItem] = useState<string>(
    () => localStorage.getItem('activeSidebarItem') || '2'
  );

  const [visibleSections, setVisibleSections] = useState<{ [key: string]: boolean }>(() => {
    const savedSections = localStorage.getItem('sidebarVisibleSections');
    return savedSections ? JSON.parse(savedSections) : {};
  });

  // Debounced update for activeItem in localStorage
  const updateActiveItem = useMemo(
    () =>
      debounce((item: string) => {
        localStorage.setItem('activeSidebarItem', item);
      }, 300),
    []
  );

  const handleSetActiveItem = useCallback(
    (item: string) => {
      setActiveItem(item);
      updateActiveItem(item);
    },
    [updateActiveItem]
  );

  const handleSectionToggle = useCallback((title: string) => {
    setVisibleSections((prev) => {
      const newState = { ...prev, [title]: !prev[title] };
      localStorage.setItem('sidebarVisibleSections', JSON.stringify(newState));
      return newState;
    });
  }, []);

  // Synchronize state across multiple tabs
  useEffect(() => {
    const syncStateAcrossTabs = (event: StorageEvent) => {
      if (event.key === 'activeSidebarItem') {
        setActiveItem(event.newValue || '');
      } else if (event.key === 'sidebarVisibleSections') {
        setVisibleSections(JSON.parse(event.newValue || '{}'));
      }
    };

    window.addEventListener('storage', syncStateAcrossTabs);
    return () => window.removeEventListener('storage', syncStateAcrossTabs);
  }, []);

  return { activeItem, visibleSections, handleSetActiveItem, handleSectionToggle };
};
