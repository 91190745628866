import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from "../store";
// import { resetTour } from "../tourSlice";
import { AppState } from '@src/app/rootReducer';
import { resetTour } from '@src/features/tour/tourSlice';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useNavigate } from 'react-router-dom';

const FltGuidedTour: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { steps, showTour, nextPage } = useSelector((state: AppState) => state.tour);
  const [tourInstance, setTourInstance] = useState<any>(null);

  useEffect(() => {
    const driverInstance = driver({
      showProgress: true,
      doneBtnText: 'Finish',
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      // allowClose: false,
      progressText: '{{current}}/{{total}}',

      onDestroyed: () => {
        if (nextPage) {
          navigate(nextPage); // Navigate to next page when tour completes
        }
        dispatch(resetTour()); // Reset tour state when completed
      },
    });

    setTourInstance(driverInstance);
  }, [navigate, dispatch, nextPage]);

  useEffect(() => {
    if (showTour && tourInstance) {
      tourInstance.setSteps(
        steps.map((step) => ({
          element: step.element,
          popover: {
            title: step.title,
            description: step.description,
            position: step.position || 'bottom',
          },
        }))
      );

      tourInstance.drive();

    }
  }, [showTour, tourInstance, steps]);

  return null; // No UI needed, just listens for state updates
};

export default FltGuidedTour;
