import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TourState {
  steps: any[]; // Tour steps for the current page
  tourKey: string | null; // Key for identifying the page
  showTour: boolean; // Controls when the tour starts
  nextPage: string | null; // Store next page to navigate after tour completion
}

const initialState: TourState = {
  steps: [],
  tourKey: null,
  showTour: false,
  nextPage: null,
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setTour: (state, action: PayloadAction<{ steps: any[]; tourKey: string; nextPage?: string  }>) => {
      state.steps = action.payload.steps;
      state.tourKey = action.payload.tourKey;
      state.showTour = true; // Start tour immediately
      state.nextPage = action.payload.nextPage || null;
    },
    resetTour: (state) => {
      state.steps = [];
      state.tourKey = null;
      state.showTour = false;
      state.nextPage = null;
    },
  },
});

export const { setTour, resetTour } = tourSlice.actions;
export default tourSlice.reducer;
