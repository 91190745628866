import { CircularProgress } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import { FltLoader } from '@src/components/base/fltLoader/fltLoader';
import { MainLayout } from '@src/components/composite/mainLayout/mainLayout';
import { db } from '@src/dbSyncManager/init';
import { lazyImport } from '@src/utils/lazyImport';
import React, { ProfilerOnRenderCallback, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

const { Dashboard } = lazyImport(() => import('../pages/misc'), 'Dashboard');
const { NotFound } = lazyImport(() => import('../pages/misc'), 'NotFound');
const { Preferences } = lazyImport(() => import('../pages/settings'), 'Preferences');
const { Notifications } = lazyImport(() => import('../pages/settings'), 'Notifications');
const { ContactInfo } = lazyImport(() => import('../pages/settings'), 'ContactInfo');
const { SecurityAndAccess } = lazyImport(() => import('../pages/settings'), 'SecurityAndAccess');
const { UserProfile } = lazyImport(() => import('../pages/settings'), 'UserProfile');
const { WorkSpaceProfile } = lazyImport(() => import('../pages/settings'), 'WorkSpaceProfile');
const { UserAndManagement } = lazyImport(() => import('../pages/settings'), 'UserAndManagement');
const { DangerZone } = lazyImport(() => import('../pages/settings'), 'DangerZone');
const { Teams } = lazyImport(() => import('../pages/teams/teams'), 'Teams');
const { GeneralSectionNew } = lazyImport(
  () => import('../pages/teams/components/teamDetails'),
  'GeneralSectionNew'
);
const { MemberSection } = lazyImport(
  () => import('../pages/teams/components/teamDetails'),
  'MemberSection'
);
const { TeamHierarchySection } = lazyImport(
  () => import('../pages/teams/components/teamDetails'),
  'TeamHierarchySection'
);
const { InitiativeDetailsSection } = lazyImport(
  () => import('../pages/initiatives/initiativeDetails'),
  'InitiativeDetailsSection'
);
const { Initiatives } = lazyImport(
  () => import('../pages/initiatives/initiativeMain'),
  'Initiatives'
);
const { InitiativeSection } = lazyImport(
  () => import('../pages/teams/components/teamDetails'),
  'InitiativeSection'
);
const { Inbox } = lazyImport(() => import('../pages/inbox'), 'Inbox');
const { Pay } = lazyImport(() => import('../pages/pay/pay'), 'Pay');
const { BillPaymentDatails } = lazyImport(
  () => import('../pages/pay/component/billPaymentDetails/billPaymentDetails'),
  'BillPaymentDatails'
);
const { CreateBill } = lazyImport(
  () => import('../pages/pay/component/createBill/createBill'),
  'CreateBill'
);
const { GetPay } = lazyImport(() => import('../pages/getPay/getPay'), 'GetPay');
const { InvoicePayDetails } = lazyImport(
  () => import('../pages/getPay/component/invoiceDetail/invoicePayDetails'),
  'InvoicePayDetails'
);

const { CreateInvoice } = lazyImport(
  () => import('../pages/getPay/component/createInvoice/createInvoice'),
  'CreateInvoice'
);
const App: React.FC = () => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('SyncStatus') != 'Synced') {
      db.syncServer();
    }
    const checkSyncStatus = () => {
      const syncStatus = localStorage.getItem('SyncStatus');
      if (syncStatus === 'Synced') {
        clearInterval(intervalId);
        if (
          window.location.pathname === '/' ||
          window.location.pathname === '/login' ||
          window.location.pathname === '/welcome' ||
          window.location.pathname === '/signup'
        ) {
          navigate('/dashboard');
        }
        setLoading(false);
      }
    };

    const intervalId = setInterval(checkSyncStatus, 500); // Check every 500ms

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

  if (loading && window.location.pathname == '/login') {
    return <FltLoader isLoading={loading} loaderText="Setting up your workspace" />;
  }
  return (
    <MainLayout>
      <Suspense
        fallback={
          <CircularProgress
            style={{ position: 'fixed', top: '50%', left: '50%' }}
            color="primary"
          />
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const onRenderCallback: ProfilerOnRenderCallback = (
  id,
  phase,
  actualDuration,
  baseDuration,
  startTime,
  commitTime
) => {
  console.log({
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
  });
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/dashboard', element: <Dashboard /> },
      { path: `/settings/my-account/preferences`, element: <Preferences /> },
      { path: `/settings/my-account/notifications`, element: <Notifications /> },
      { path: `/settings/my-account/profile`, element: <UserProfile /> },
      { path: `/settings/my-account/security-and-access`, element: <SecurityAndAccess /> },
      { path: `/settings/my-account/contact-information`, element: <ContactInfo /> },
      { path: `/settings/company-workspace/profile`, element: <WorkSpaceProfile /> },
      { path: `/settings/company-workspace/user-management`, element: <UserAndManagement /> },
      { path: `/settings/company-workspace/danger-zone`, element: <DangerZone /> },
      {
        path: '/teams',
        element: <Teams />,
      },
      { path: '/groups/teams/details/:id/general', element: <GeneralSectionNew /> },
      { path: '/groups/teams/details/:id/members', element: <MemberSection /> },
      { path: '/groups/teams/details/:id/hierarchy-views', element: <TeamHierarchySection /> },
      {
        path: '/groups/teams/:id/initiatives/:initiativeid',
        element: <InitiativeDetailsSection />,
      },
      { path: '/initiatives', element: <Initiatives /> },
      { path: '/groups/teams/:id/initiatives', element: <InitiativeSection /> },
      { path: '/teams/:id/pay', element: <Pay /> },
      { path: '/teams/:id/pay/:billId/billDetails', element: <BillPaymentDatails /> },
      { path: '/teams/:id/bills/create', element: <CreateBill /> },
      { path: '/teams/:id/bills/:billId/edit', element: <CreateBill /> },

      { path: '/teams/:id/getpay', element: <GetPay /> },
      { path: '/teams/:id/getpay/:billId/payDetails', element: <InvoicePayDetails /> },
      { path: '/teams/:id/invoices/create', element: <CreateInvoice /> },
      { path: '/teams/:id/invoices/:billId/edit', element: <CreateInvoice /> },

      {
        path: '/inbox',
        element: <Inbox />,
      },
      {
        path: '/inbox/:id',
        element: <Inbox />,
      },
      { path: '*', element: <NotFound /> },
    ],
  },
];
