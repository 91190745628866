import { SidebarItem } from "./sidebarItem/sidebarItem";

  export const mainMenuSidebarItems: SidebarItem[] = [
    {
      id: '1',
      title: 'Dashboard',
      route: '/dashboard',
      icon: 'SquaresFour',
    },
    {
      id: '2',
      title: 'Inbox',
      route: '/inbox',
      icon: 'Tray',
    },
  ];
  export const companyWorkspaceSidebarItems: SidebarItem[] = [
    {
      id: '3',
      title: 'Teams',
      route: '/teams',
      icon: 'Users',
      // iconColor: "rgba(88, 162, 0)",
      // iconBg: "rgba(88, 162, 0, 0.12)",
    },
    {
      id: '4',
      title: 'Initiatives',
      route: '/initiatives',
      icon: 'MagicWand',
    },
    {
      id: '5',
      title: 'Reports',
      route: '/about',
      icon: 'Folders',
    },
    {
      id: '6',
      title: 'Commerce',
      icon: 'Cube',

      children: [
        { id: '6-1', title: 'Customers and Vendors', icon: 'UsersThree', route: '/' },
        { id: '6-2', title: 'Products and Services', icon: 'CubeFocus', route: '/' },
        { id: '6-3', title: 'Taxes', icon: 'MoneyWavy', route: '/services/web' },
        { id: '6-4', title: 'Shipping Methods', icon: 'Truck', route: '/' },
        { id: '6-5', title: 'Discounts', icon: 'SealPercent', route: '/' },
      ],
    },
  ];