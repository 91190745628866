import { AppState } from '@src/app/rootReducer';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FltIcon from '../../fltIcon/fltIcon';
import { FltButtonProps } from './fltIconButtonInterface';
import { FltButtonWrapper } from './fltIconButtonStyles';
const FltIconButton = ({
  name,
  size = 'large',
  children,
  type,
  isLoading,
  action,
  disabled,
  onClick,
  shape,
  styles,
  onMouseEnter,
  onMouseLeave,
  btnStatus,
  borderNone = false,
  iconColor = 'currentColor'
}: FltButtonProps) => {
  const theme = useSelector((data: AppState) => data.theme.theme);
  const [isActive, setIsActive] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Handle click outside of the button
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsActive(!isActive); // Toggle the active state on button click
    if (onClick) onClick(event);
  };

  // const iconWeight = isActive ? 'fill' : 'regular';
  const iconSize = size === 'small' ? 14 : 16;

  return (
    <FltButtonWrapper
      ref={buttonRef}
      type={action}
      size={size}
      btnType={type}
      disabled={disabled}
      theme={theme}
      shape={shape}
      styles={styles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      btnStatus={btnStatus}
      onClick={handleButtonClick}
      isActive={isActive}
      borderNone={borderNone}
    >
      {name ? <FltIcon name={name} iconColor={iconColor} size={iconSize} /> :
      children
      }
    </FltButtonWrapper>
  );
};

export default FltIconButton;
