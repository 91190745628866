import styled, { css, CSSObject, RuleSet } from 'styled-components';
import { TextInputWrapperProps } from './fltTextInputTypes';

export const TextInputWrapper = styled.div<TextInputWrapperProps>`
  width: ${({ width }) => (width ? width : '100%')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '')};
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* padding: 8px 16px; */
  .input-area {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;

    .color-picker-div {
      width: 24px;
      height: 24px;
      border-radius: var(--Spacing-3, 6px) var(--spacing-0x, 0px) var(--spacing-0x, 0px)
        var(--Spacing-3, 6px);

      position: relative;

      .react-colorful {
        position: absolute;
        z-index: 1000;
        top: 28px;
        left: -70px;
      }
    }

    ${({ theme, appearance }) =>
      appearance === 'color-picker'
        ? css`
            border: 1px solid ${theme.border.secondary};
            border-radius: 6px;

            &:focus {
              border: 1.5px solid ${theme.border.accent};
              box-shadow: 0px 0px 0px 2px
                ${theme.themeType == 'light' ? 'rgba(2, 78, 174, 0.20)' : ' rgba(2, 78, 174, 0.40)'};
              color: ${theme.fltInput.text.active.primary};
              cursor: text;
              &:hover {
                border: 1.5px solid ${theme.border.primary};
                box-shadow: 0px 0px 0px 2px
                  ${theme.themeType == 'light'
                    ? 'rgba(2, 78, 174, 0.20)'
                    : ' rgba(2, 78, 174, 0.40)'};
                color: ${theme.fltInput.text.active.primary};
              }
            }

            &:hover {
              border-color: ${theme.border.primary};
              box-shadow: 0px 1px 4px 0px
                ${theme.themeType == 'light'
                  ? 'rgba(212, 212, 212, 0.16)'
                  : ' rgba(14, 14, 14, 0.16)'};
              color: ${theme.content.secondary};

              &::placeholder {
                color: ${({ theme }) =>
                  theme.content.secondary}; /* Add this to change the placeholder color */
              }
            }

            &:active {
              border: 1px solid ${theme.border.accent};
              box-shadow: 0px 1px 4px 0px
                ${theme.themeType == 'light'
                  ? 'rgba(212, 212, 212, 0.16)'
                  : ' rgba(14, 14, 14, 0.16)'};
              color: ${theme.content.primary};
            }

            &.valid {
              border: 1px solid ${theme.fltInput.text.valid};
              &:hover {
                border: 1px solid ${theme.fltInput.text.valid};
              }
            }

            &.error {
              border: 1px solid ${theme.border.error};
              color: ${theme.fltInput.text.error};
              &:hover {
                border: 1px solid ${theme.fltInput.border.error};
              }
              &::placeholder {
                color: ${theme.fltInput.text.error}; /* Add this to change the placeholder color */
              }
            }
          `
        : css`
            border: none;
          `}

    .icon-div {
      position: absolute;
      z-index: 100;
      padding: ${({ size }) => (size === 'x-sm' ? '0 8px' : size === 'sm' ? '0 10px' : '0 14px')};
      display: flex;
      align-items: center;
    }

    input {
      position: relative;
      z-index: 100;
      height: 32px;
      padding: ${({ glyphs='none', size='default' }) => {
        let basePadding;

        // Define default padding values
        const paddingMap:any = {
          none: { 'x-sm': '0px 6px', sm: '0px 8px', default: '0px 12px' },
          both: { 'x-sm': '0 24px 0 24px', sm: '0 28px 0 28px', default: '0 36px 0 36px' },
          left: { 'x-sm': '0 6px 0 24px', sm: '0 8px 0 28px', default: '0 12px 0 36px' },
          right: { 'x-sm': '0 24px 0 6px', sm: '0 28px 0 8px', default: '0 36px 0 12px' },
        };

        // Ensure `glyphs` has a valid value; fallback to "none"
        basePadding = paddingMap[glyphs]
          ? paddingMap[glyphs][size] || paddingMap[glyphs].default
          : '0px 12px';

        return basePadding;
      }};

      padding-top: 8px;
      padding-bottom: 8px;
      user-select: none;
      overflow: hidden;
      color: ${({ theme }) => theme.fltInput.text.primary};
      /* background-color: ${({ theme }) => theme.surface.background.secondary}; */
      background-color: transparent;
      transition:
        background-color 0.15s ease,
        border 0.15s ease,
        color 0.15s ease,
        box-shadow 0.15s ease; /* Smooth transition for background, color, border, etc. */
      cursor: pointer;
      border-radius: 6px;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.24px;

      ${({ styles }) =>
        styles?.padding &&
        css`
          padding: ${styles.padding};
        `}

      &::placeholder {
        color: ${({ theme }) =>
          theme.content.tertiary}; /* Add this to change the placeholder color */
        font-family: 'Inter', sans-serif;
        font-size: 12px;
      }

      ${({ theme, appearance }) =>
        appearance === 'border'
          ? css`
              border: 1px solid ${theme.border.secondary};

              &:focus {
                border: 1.5px solid ${theme.border.accent};
                box-shadow: 0px 0px 0px 2px
                  ${theme.themeType == 'light'
                    ? 'rgba(2, 78, 174, 0.20)'
                    : ' rgba(2, 78, 174, 0.40)'};
                color: ${theme.fltInput.text.active.primary};
                cursor: text;
                &:hover {
                  border: 1.5px solid ${theme.border.accent};
                  box-shadow: 0px 0px 0px 2px
                    ${theme.themeType == 'light'
                      ? 'rgba(2, 78, 174, 0.20)'
                      : ' rgba(2, 78, 174, 0.40)'};
                  color: ${theme.fltInput.text.active.primary};
                }
              }

              &:hover {
                border-color: ${theme.border.primary};
                box-shadow: 0px 1px 4px 0px
                  ${theme.themeType == 'light'
                    ? 'rgba(212, 212, 212, 0.16)'
                    : ' rgba(14, 14, 14, 0.16)'};
                color: ${theme.content.secondary};

                &::placeholder {
                  color: ${({ theme }) =>
                    theme.content.secondary}; /* Add this to change the placeholder color */
                }
              }

              &:active {
                border: 1px solid ${theme.border.accent};
                box-shadow: 0px 1px 4px 0px
                  ${theme.themeType == 'light'
                    ? 'rgba(212, 212, 212, 0.16)'
                    : ' rgba(14, 14, 14, 0.16)'};
                color: ${theme.content.primary};
              }

              &.valid {
                border: 1px solid ${theme.fltInput.text.valid};
                &:hover {
                  border: 1px solid ${theme.fltInput.text.valid};
                }
              }

              &.error {
                border: 1px solid ${theme.border.error};
                color: ${theme.fltInput.text.error};
                &:hover {
                  border: 1px solid ${theme.fltInput.border.error};
                }
                &::placeholder {
                  color: ${theme.fltInput.text
                    .error}; /* Add this to change the placeholder color */
                }
              }
            `
          : css`
              border: none;
            `}

      ${({ size }) =>
        size === 'sm' &&
        css`
          height: 28px;
          font-size: 10px;
        `}
        
      ${({ size }) =>
        size === 'default' &&
        css`
          height: 32px;
        `}

        ${({ size }) =>
        size === 'x-sm' &&
        css`
          height: 24px;
        `}

      ${({ inputStyles }) => inputStyles && css(inputStyles as CSSObject)}
    }

    /* For all number input fields */
    input[type='number'] {
      -moz-appearance: textfield; /* Firefox */
      -webkit-appearance: none; /* Chrome, Safari, Edge */
      appearance: none; /* General */
      margin: 0; /* Reset default margin */
    }

    /* Hide spinners for WebKit-based browsers */
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  .icon-container {
    position: absolute;
    right: 0px;
    z-index: 1000;
    width: 30px;
    display: flex;
    align-items: center;
    padding-left: 0.25rem;
    cursor: pointer;

    svg {
      color: ${({ theme }) => theme.icon.colorPrimary};
    }
  }

  .error-message {
    animation: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s 1 normal both running fadeIn;
    /* margin-top: 8px; */
    padding-left: 2px;
    font-style: normal;
    color: ${({ theme }) => theme.fltInput.text.error};
    line-height: normal;
    font-size: 11px;
    font-weight: normal;
  }
  ${({ styles }) => styles && css(styles as CSSObject)}
`;
