import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '@src/features/auth/authSlice';
import displayNameReducer from '@src/features/displayName/displayNameSlice';
import accountReducer from '@src/features/entity/accounts/accountSlice';
import addressReducer from '@src/features/entity/addresses/addressesSlice';
import documentLineItemReducers from '@src/features/entity/documentLineItems/documentLineItemsSlice';
import documentLineItemTaxReducers from '@src/features/entity/documentLineItemTaxes/documentLineItemTaxesSlice';
import documentReducer from '@src/features/entity/documents/documentsSlice';
import emailReducer from '@src/features/entity/emails/emailsSlice';
import eventSubscriptionsReducer from '@src/features/entity/eventSubscriptions/eventSubscriptionsSlice';
import groupsReducer from '@src/features/entity/groups/groupsSlice';
import initiativesReducer from '@src/features/entity/initiatives/initiativesSlice'; //
import partyReducer from '@src/features/entity/parties/partiesSlice';
import paymentReducers from '@src/features/entity/payments/paymentsSlice';
import periodsReducer from '@src/features/entity/periods/periodsSlice';
import phoneNumberReducer from '@src/features/entity/phoneNumbers/phoneNumbersSlice';
import preferenceReducer from '@src/features/entity/preference/preferenceSlice';
import processedEventMessageReducer from '@src/features/entity/processedEventMessages/processedEventMessageSlice';
import programsReducer from '@src/features/entity/programs/programsSlice'; //
import publishedEventsReducer from '@src/features/entity/publishedEvents/publishedEventsSlice';
import teamsReducer from '@src/features/entity/teams/teamsSlice';
import userRelshipReducer from '@src/features/entity/userRelship/userRelshipSlice';
import usersReducer from '@src/features/entity/users/usersSlice';
import sideBarReducer from '@src/features/sideBar/sideBarSlice';
import initialTeamReducer from '@src/features/signup/initialTeamSlice';
import introductionReducer from '@src/features/signup/introductionSlice';
import otpReducer from '@src/features/signup/otpSlice';
import signupApiReducer from '@src/features/signup/signupApiSlice';
import signupReducer from '@src/features/signup/signupSlice';
import workspaceReducer from '@src/features/signup/workspaceSlice';
import themeReducer from '@src/features/theme/themeSlice';
import lineItemReducer from '@src/features/document/lineItemSlice'
import billReducer from '@src/features/document/billSlice';
import documentAddressReducer from '@src/features/entity/documentAddress/documentAddressSlice';
import productsReducer from '@src/features/entity/products/productsSlice';
import productTaxesReducer from '@src/features/entity/productTaxes/productTaxesSlice';
import taxesReducer from '@src/features/entity/taxes/taxesSlice';
import workObkectsReducer from '@src/features/entity/workObjects/workObjectsSlice';
import tourReducer from '@src/features/tour/tourSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  displayName: displayNameReducer,
  preference: preferenceReducer,
  periods: periodsReducer,
  groups: groupsReducer,
  userRelship: userRelshipReducer,
  teams: teamsReducer,
  initiatives: initiativesReducer,
  programs: programsReducer,
  sideBar: sideBarReducer,
  users: usersReducer,
  processedEventMessage: processedEventMessageReducer,
  publishedEvents: publishedEventsReducer,
  eventSubscriptions: eventSubscriptionsReducer,
  documents: documentReducer,
  parties: partyReducer,
  documentLineItems: documentLineItemReducers,
  documentLineItemTaxes: documentLineItemTaxReducers,
  payments: paymentReducers,
  emails: emailReducer,
  accounts: accountReducer,
  addresses: addressReducer,
  phoneNumbers: phoneNumberReducer,
  signup: signupReducer,
  signupApi: signupApiReducer,
  introduction: introductionReducer,
  workspace: workspaceReducer,
  initialTeam: initialTeamReducer,
  otp: otpReducer,
  documentAddress: documentAddressReducer,
  productTaxes: productTaxesReducer,
  products: productsReducer,
  taxes: taxesReducer,
  workObjects: workObkectsReducer,
  bill:billReducer,
  lineItem:lineItemReducer,
  tour: tourReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
