import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const FltDynamicDropdownWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  width: 100%;
  .action-btn {
    border-radius: 4px;
    font-weight: 400;
    line-height: normal;
    transition-property: border, background-color, color, opacity;
    transition-duration: 0.15s;
    user-select: none;
    box-shadow: ${({ theme }) => ` ${theme.fltMenu.boxShadow.primary}  0px 4px 4px -1px`};
    min-width: 24px;
    height: 100%;
    padding: 0px 8px;
    font-size: 12px;

    cursor: pointer;

    span {
      color: ${({ theme }) => theme.fltMenu.menuItem.text.primary};
    }
  }

  .action-btn:hover {
    background-color: ${({ theme }) => theme.fltMenu.background.primary};
    color: ${({ theme }) => theme.fltMenu.menuItem.text.primary};
    border: none;
  }
`;
