import { CircularProgress, circularProgressClasses } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';
import FltIcon from '../../fltIcon/fltIcon';
import { FltButtonDropdownProps, FltButtonProps, Shape, Type } from './fltButtonInterface';
import { FltButtonWrapper, LoaderDiv } from './fltButtonStyles';

const FltButton = React.forwardRef<HTMLButtonElement | HTMLDivElement, FltButtonProps>(
  (
    {
      size = 'default',
      rightGlyph,
      leftGlyph,
      type,
      isLoading,
      action,
      disabled,
      children,
      onClick,
      // appearance = 'filled',
      shape,
      childLayout = 'default',
      btnFor = 'normal-btn',
      width,
      styles,
      ...restProps
    },
    ref // ref will now be forwarded to this component
  ) => {
    const theme = useSelector((data: AppState) => data.theme.theme);

    // Determine the additional className based on isDropdownOpen
    const dropdownClass =
      btnFor === 'dropdown-btn' && (restProps as FltButtonDropdownProps).isDropdownOpen
        ? 'dropdown-open'
        : '';
    const iconName =
      btnFor === 'dropdown-btn' && (restProps as FltButtonDropdownProps).isDropdownOpen
        ? 'CaretUp'
        : 'CaretDown';

    return (
      <FltButtonWrapper
        type={action}
        size={size}
        btnType={type}
        styles={styles}
        onClick={onClick}
        disabled={disabled}
        theme={theme}
        shape={shape}
        childLayout={childLayout}
        className={dropdownClass}
        btnFor={btnFor}
        {...restProps}
        width={width}
        ref={ref as any} // Forward ref here
      >
        {(leftGlyph || children || rightGlyph || isLoading) && (
          <div className="btn-content">
            <>
              <div className="btn-left-content">
                {leftGlyph}
                {children && <span className="children-container">{children}</span>}
              </div>
              {btnFor === 'dropdown-btn' ? (
                <FltIcon weight="thin" name={iconName} size={18} />
              ) : (
                rightGlyph
              )}
            </>
          </div>
        )}

        {isLoading && (
          <LoaderDiv theme={theme} btnType={type as Type} shape={shape as Shape}>
            <CircularProgress
              variant="determinate"
              sx={(theme) => ({
                color: theme.palette.grey[200],
                ...theme.applyStyles('dark', {
                  color: theme.palette.grey[800],
                }),
              })}
              size={size == 'small' ? 14 : 16}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={(theme) => ({
                color: '#1a90ff',
                animationDuration: '1s',
                position: 'absolute',
                // left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
                ...theme.applyStyles('dark', {
                  color: '#308fe8',
                }),
              })}
              size={size == 'small' ? 14 : 16}
              thickness={4}
              // {...props}
            />
          </LoaderDiv>
        )}
      </FltButtonWrapper>
    );
  }
);

export default FltButton;
