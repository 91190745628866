import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface SelectedTax {
  lineItemId: number;
  taxId: any;
  updateId?: any;
}
const initialState={
  pricePerUnit: '',
  productId: '',
  units: '',
  taxId: '',
  description: '',
  removeLineItemIds: [] as number[],
  selectedTaxAmount: [] as any[],
  selectedTaxes: [] as SelectedTax[],
  selectedUnits: [] as any[],
  selectedPriceperUnit: [] as any[],
  selectedName: [] as any,
  selectedDescription: [] as any,
  selectedProductId: [] as any,
}
const lineItemSlice = createSlice({
  name: 'bill',
  initialState: initialState,
  reducers: {
    setPricePerUnit: (state, action: PayloadAction<string>) => {
      state.pricePerUnit = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setUnits: (state, action) => {
      state.units = action.payload;
    },
    setTaxId: (state, action) => {
      state.taxId = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    removeLineItemId: (state, action: PayloadAction<number>) => {
      // Removes an ID from the array
      state.removeLineItemIds.push(action.payload);
    },
    setLineItemIdsToRemove: (state, action: PayloadAction<number[]>) => {
      // Sets the entire array of IDs
      state.removeLineItemIds = action.payload;
    },
    setSelectedTax: (state, action: PayloadAction<SelectedTax>) => {
      // Add or update a selected tax for a specific lineItemId
      const { lineItemId, taxId, updateId } = action.payload;
      const existingIndex = state.selectedTaxes.findIndex((item) => item.lineItemId === lineItemId);

      if (existingIndex !== -1) {
        // Update existing entry
        state.selectedTaxes[existingIndex] = { lineItemId, taxId, updateId };
      } else {
        // Add new entry
        state.selectedTaxes.push({ lineItemId, taxId, updateId });
      }
    },
    setSelectedTaxAmount: (state, action: PayloadAction<any>) => {
      // Add or update a selected tax for a specific lineItemId
      const { lineItemId, index } = action.payload;
      const existingIndex = state.selectedTaxAmount.findIndex((item: any) => {
        if (item.lineItemId) return item.lineItemId == lineItemId;
        else return item.index == index;
      });
      if (existingIndex !== -1) {
        // Update existing entry
        state.selectedTaxAmount[existingIndex] = action.payload;
      } else {
        // Add new entry
        state.selectedTaxAmount.push(action.payload);
      }
    },
    setselectedUnits: (state, action: PayloadAction<any>) => {
      // Add or update a selected tax for a specific lineItemId
      const { lineItemId, units } = action.payload;
      const existingIndex = state.selectedUnits.findIndex((item) => item.lineItemId === lineItemId);

      if (existingIndex !== -1) {
        // Update existing entry
        state.selectedUnits[existingIndex] = { lineItemId, units };
      } else {
        // Add new entry
        state.selectedUnits.push({ lineItemId, units });
      }
    },
    setSelectedPrice: (state, action: PayloadAction<any>) => {
      // Add or update a selected tax for a specific lineItemId
      const { lineItemId, price } = action.payload;
      const existingIndex = state.selectedPriceperUnit.findIndex(
        (item) => item.lineItemId === lineItemId
      );

      if (existingIndex !== -1) {
        // Update existing entry
        state.selectedPriceperUnit[existingIndex] = { lineItemId, price };
      } else {
        // Add new entry
        state.selectedPriceperUnit.push({ lineItemId, price });
      }
    },
    setSelectedDescription: (state, action: PayloadAction<any>) => {
      // Add or update a selected tax for a specific lineItemId
      const { lineItemId, description } = action.payload;
      const existingIndex = state.selectedDescription.findIndex(
        (item: any) => item.lineItemId === lineItemId
      );

      if (existingIndex !== -1) {
        // Update existing entry
        state.selectedDescription[existingIndex] = { lineItemId, description };
      } else {
        // Add new entry
        state.selectedDescription.push({ lineItemId, description });
      }
    },
    setSelectedProductId: (state, action: PayloadAction<any>) => {
      const { lineItemId, index } = action.payload;
      const existingIndex = state.selectedProductId.findIndex((item: any) => 
        item.lineItemId === lineItemId || item.index === index
      );
    
      if (existingIndex !== -1) {
        // Create a new array with updated value
        state.selectedProductId = state.selectedProductId.map((item:any, idx:any) =>
          idx === existingIndex ? action.payload : item
        );
      } else {
        // Add new entry
        state.selectedProductId = [...state.selectedProductId, action.payload];
      }
    },
    
    // setSelectedProductId: (state, action: PayloadAction<any>) => {
    //   // Add or update a selected tax for a specific lineItemId
    //   const { lineItemId, index } = action.payload;
    //   const existingIndex = state.selectedProductId.findIndex((item: any) => {
    //     if (item.lineItemId) return item.lineItemId == lineItemId;
    //     else return item.index == index;
    //   });
    //   if (existingIndex !== -1) {
    //     // Update existing entry
    //     state.selectedProductId[existingIndex] = action.payload;
    //   } else {
    //     // Add new entry
    //     state.selectedProductId.push(action.payload);
    //   }
    // },
    resetState: (state) => {
      return {
        pricePerUnit: '',
        productId: '',
        units: '',
        taxId: '',
        description: '',
        removeLineItemIds: [],
        selectedTaxAmount: [],
        selectedTaxes: [],
        selectedUnits: [],
        selectedPriceperUnit: [],
        selectedName: [],
        selectedDescription: [],
        selectedProductId: [],
      };
    }
    
  },
});

export const {
  setPricePerUnit,
  setProductId,
  setUnits,
  setTaxId,
  setDescription,
  removeLineItemId,
  setLineItemIdsToRemove,
  setSelectedTax,
  setselectedUnits,
  setSelectedPrice,
  setSelectedDescription,
  setSelectedProductId,
  setSelectedTaxAmount,resetState
} = lineItemSlice.actions;
export default lineItemSlice.reducer;
