import { Drawer, useMediaQuery } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import Logo from '@src/assets/images/icon_flute-temp.png';
import FltButton from '@src/components/base/buttons/fltButton/fltButton';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText from '@src/components/base/fltText/fltText';
import { FltDynamicDropdown } from '@src/components/base/menus/fltAdvenceDropdownMenu/FltDynamicDropdown';
import { toggelSideBar } from '@src/features/sideBar/sideBarSlice';
import { setTour } from '@src/features/tour/tourSlice';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PrimarySideBarWrapper } from './primarySidebarStyle';
import SidebarMenuItem, { SidebarItem } from './sidebarItem/sidebarItem';
import { companyWorkspaceSidebarItems, mainMenuSidebarItems } from './primarySidebarData';
import { tourStepsConfig } from '@src/features/tour/tourSteps';
import { useSidebarState } from '../hook/useSidebarState';

type PrimarySidebarProps = {
  collapse: boolean;
  setCollapse: Dispatch<SetStateAction<boolean>>;
};

export const PrimarySideBar = ({ collapse, setCollapse }: PrimarySidebarProps) => {
  const themes = useSelector((state: AppState) => state.theme.theme);
  const isMobile = useMediaQuery('(max-width:768px)');
  const location = useLocation();
  const mode = useSelector((state: AppState) => state.theme.mode);
  const userRelship = useSelector((data: AppState) => data.userRelship.arrayData);
  const groups = useSelector((data: AppState) => data.groups.arrayData);
  const userId = useSelector((state: AppState) => state.auth.userId);
  // const [activeItem, setActiveItem] = useState<string>('2');
  const [yourTeams, setYourTeams] = useState<SidebarItem[]>([]);
  const dispatch = useDispatch();
  const { activeItem, visibleSections, handleSetActiveItem, handleSectionToggle } = useSidebarState();
  useEffect(() => {
    if (userRelship && groups && userId) {
      const filteredUserRelshipData = userRelship.filter(
        (data) => data.userId === userId && data.type === 'TEAM'
      );

      // Filter groups based on filteredUserRelshipData
      let filteredGroups = groups.filter((group) =>
        filteredUserRelshipData.some((user) => user.forId === group.id)
      );

      // Sort filteredGroups alphabetically by data.name
      filteredGroups = filteredGroups.sort((a, b) => a.name.localeCompare(b.name));

      // Map the sorted groups to yourTeamsNavItems
      const yourTeamsNavItems = filteredGroups.map((data, index) => {
        return {
          id: `${index + 7}`,
          title: data.avatarInitials ? `${data.avatarInitials} - ${data.name}` : data.name,
          type: 'Teams',
          icon: data.icon ? data.icon : '',
          iconColor: data.color ? data.color : '',
          iconSource: '',
          children: [
            {
              id: `${index + 7}-1`,
              title: 'Initiatives',
              icon: 'MagicWand',
              route: `/groups/teams/${data.id}/initiatives`,
            },
            {
              id: `${index + 7}-2`,
              title: 'Pay',
              icon: 'HandCoins',
              route: `/teams/${data.id}/pay`,
            },
            {
              id: `${index + 7}-3`,
              title: 'Get Paid',
              icon: 'HandArrowDown',
              route: `/teams/${data.id}/getpay`,
            },
            { id: `${index + 7}-4`, title: 'Reports', icon: 'Folders', route: '/' },
            {
              id: `${index + 7}-5`,
              title: 'Settings',
              icon: 'GearSix',
              route: `/groups/teams/details/${data.id}/general`,
            },
          ],
        };
      });

      setYourTeams(yourTeamsNavItems);
    }
  }, [userRelship, groups, userId]);

  // const [visibleSections, setVisibleSections] = useState<{ [key: string]: boolean }>({});
  // console.log("debug - visibleSections - top level",visibleSections);
  // const handleSectionToggle = (title: string) => {
  //   setVisibleSections((prevState) => ({
  //     ...prevState,
  //     [title]: !prevState[title],
  //   }));
  // };

  // const [visibleSections, setVisibleSections] = useState<{ [key: string]: boolean }>(() => {
  //   // Initialize from localStorage or default to an empty object
  //   const savedSections = localStorage.getItem('sidebarVisibleSections');
  //   return savedSections ? JSON.parse(savedSections) : {};
  // });
  
  // const handleSectionToggle = (title: string) => {
  //   setVisibleSections((prevState) => {
  //     const newState = {
  //       ...prevState,
  //       [title]: !prevState[title],
  //     };
  //     // Save the updated state to localStorage
  //     localStorage.setItem('sidebarVisibleSections', JSON.stringify(newState));
  //     return newState;
  //   });
  // };
  
  // useEffect(() => {
  //   // Optional: Sync localStorage if it changes elsewhere in the app
  //   const savedSections = localStorage.getItem('sidebarVisibleSections');
  //   if (savedSections) {
  //     setVisibleSections(JSON.parse(savedSections));
  //   }
  // }, []);
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const handleMouseEnter = (iconName: string) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  useEffect(() => {
    setHoveredIcon(null);
  }, [collapse]);

  const getMatchingTour = (pathname: string) => {
    const pathParts = pathname.split('/').filter(Boolean); // Remove empty parts
    let normalizedParts: string[] = [];
    let dynamicValues: Record<string, string> = {}; // Store extracted dynamic values

    pathParts.forEach((part, index) => {
      if (isNaN(Number(part))) {
        normalizedParts.push(part); // Keep non-numeric parts
      } else {
        dynamicValues[`param${index}`] = part; // Store numeric parts
        normalizedParts.push(':id'); // Replace numeric parts with ':id'
      }
    });

    const normalizedPath = normalizedParts.join('/');


    // Find the best-matching tour
    const matchingRoute = Object.keys(tourStepsConfig).find((pattern) =>
      normalizedPath.startsWith(pattern)
    );

    return { matchingRoute, dynamicValues };
  };

  const handleStartTour = () => {
    // const steps = tourStepsConfig[location.pathname] || [];

    // const matchingRoute = getMatchingTour(location.pathname);
    const { matchingRoute, dynamicValues } = getMatchingTour(location.pathname);
    const steps = matchingRoute ? tourStepsConfig[matchingRoute] : [];

    if (steps.length > 0) {
      if (matchingRoute) {
        let nextPage: string | undefined = undefined;
        // if (matchingRoute == 'teams/:id/pay') {
        //   nextPage = `/teams/${dynamicValues.param1}/bills/create`;
        // }
        dispatch(setTour({ steps, tourKey: matchingRoute, nextPage }));
      }
    } else {
      alert('No tour available for this page.');
    }

  };

  const SidebarContent = (
    <>
      <div className="sidebar-header">
        <div className="left-container">
          <img src={Logo} title="flute" alt="flute" width="20px" height="20px" />
          {
            <FltText
              variant="display-large"
              styles={{ color: themes.pages.layout.sidebar.text.secondary }}
            >
              Flute
            </FltText>
          }
        </div>
        <div
          className="right-container"
          onClick={() => {
            dispatch(toggelSideBar());
          }}
          onMouseEnter={() => handleMouseEnter('SidebarLeft')}
          onMouseLeave={handleMouseLeave}
        >
          {
            <FltIcon
              weight={hoveredIcon === 'SidebarSimple' ? 'bold' : 'regular'}
              name="SidebarSimple"
              size={16}
            />
          }
        </div>
      </div>
      <div className="content">
        <div className="create-btn-container">
          <FltButton
            type="primary"
            size="default"
            width="100%"
            rightGlyph={
              <FltIcon weight="regular" name="Plus" size={18} iconColor="content-accentText" />
            }
          >
            {
              <FltText variant="heading-medium" textColor="content-accentText">
                Create
              </FltText>
            }
          </FltButton>
        </div>
        <div className={'divider'}></div>
        <div>
          <div className="side-bar-group-item-container">
            {mainMenuSidebarItems.map((item) => (
              <SidebarMenuItem
                collapsed={collapse}
                key={item.id}
                item={item}
                activeItem={activeItem}
                // setActiveItem={setActiveItem}
                setActiveItem={handleSetActiveItem}
                visibleSections={visibleSections}
                handleSectionToggle={handleSectionToggle}
              />
            ))}
          </div>
          <div className={'divider'}></div>
          <div className="side-bar-group-item-container">
            <div
              className="nav-item-group-title-container"
              onMouseEnter={() => handleMouseEnter('Company-Workspace')}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSectionToggle('Company-Workspace')}
            >
              <FltText variant="heading-small" styles={{ color: 'inherit' }}>
                Company Workspace
              </FltText>
              <FltIcon
                name={!visibleSections['Company-Workspace'] ? 'CaretDown' : 'CaretRight'}
                weight="fill"
                size={12}
              />
            </div>
            {!visibleSections['Company-Workspace'] &&
              companyWorkspaceSidebarItems.map((item) => (
                <SidebarMenuItem
                  key={item.id}
                  item={item}
                  collapsed={collapse}
                  activeItem={activeItem}
                  // setActiveItem={setActiveItem}
                  setActiveItem={handleSetActiveItem}
                  visibleSections={visibleSections}
                  handleSectionToggle={handleSectionToggle}
                />
              ))}
          </div>
          <div className={'divider'}></div>
          <div className="side-bar-group-item-container">
            <div
              className="nav-item-group-title-container"
              onMouseEnter={() => handleMouseEnter('Your-Teams')}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSectionToggle('Your-Teams')}
            >
              <FltText variant="heading-small" styles={{ color: 'inherit' }}>
                Your Teams
              </FltText>
              <FltIcon
                name={!visibleSections['Your-Teams'] ? 'CaretDown' : 'CaretRight'}
                weight="fill"
                size={12}
                // color={themes.pages.layout.sidebar.icon.quaternary}
              />
            </div>
            {!visibleSections['Your-Teams'] &&
              yourTeams.map((item) => (
                <SidebarMenuItem
                  collapsed={collapse}
                  key={item.id}
                  item={item}
                  activeItem={activeItem}
                  // setActiveItem={setActiveItem}
                  setActiveItem={handleSetActiveItem}
                  visibleSections={visibleSections}
                  handleSectionToggle={handleSectionToggle}
                />
              ))}
          </div>
          {/* <div className="divider"></div> */}
        </div>
      </div>

      <FltDynamicDropdown
        selectOptions={[
          { label: 'Take a tour', icon: 'BookOpenUser' },
          { label: 'Shortcuts', icon: 'Keyboard' },
        ]}
        menuStyle={{ width: '180px' }}
        onClick={(params: { data: object; otherData: object }) => {
          const supportMenuData = params.data as { label: string; icon: string };
          if (supportMenuData.label === 'Take a tour') {
            handleStartTour();
          }
        }}
        menuItemRenderer={(params) => {
          return (
            <div className="support-menu-renderer">
              <div className="support-menu-right-container">
                <FltIcon
                  iconColor="content-tertiary"
                  name={params?.icon}
                  size={14}
                />
                <FltText variant="heading-small" textColor="content-primary">
                  {params?.label}
                </FltText>
              </div>
              {params?.label === 'Shortcuts' && (
                <FltText variant="label-small" textColor="content-tertiary">
                  Ctrl+shift+?
                </FltText>
              )}
            </div>
          );
        }}
        type="dynamic"
        buttonComponent={
          <button className="flute-support-btn" id="flute-support-button">
            <FltIcon
              // weight={hoveredIcon === 'SidebarSimple' ? 'bold' : 'regular'}
              iconColor="content-tertiary"
              name="Question"
              size={14}
            />
            <FltText variant="label-small" textColor="content-secondary">
              Need help
            </FltText>
          </button>
        }
      />
    </>
  );

  const [openDrawerSideBar, setOpenDrawerSideBar] = useState(false);

  const handleMouseEnterOnHoverHadler = () => {
    setOpenDrawerSideBar(true);
  };

  const handleMouseLeaveOnDrawer = () => {
    setOpenDrawerSideBar(false);
  };

  const [isDrawer, setIsDrawer] = useState(false);

  useEffect(() => {
    const updateSidebarMode = () => {
      setIsDrawer(window.innerWidth < 768);
      // if (window.innerWidth >= 992) {
      //   setIsOpen(true); // Keep sidebar open on larger screens
      // }
    };

    updateSidebarMode();
    window.addEventListener("resize", updateSidebarMode);
    return () => window.removeEventListener("resize", updateSidebarMode);
  }, []);

  return (
    // <PrimarySideBarWrapper
    //   theme={themes}
    //   collapsed={collapse}
    //   openDrawerSideBar={openDrawerSideBar}
    //   mode={mode}
    // >
    //   {collapse && (
    //     <div className="side-bar-hover-handler" onMouseEnter={handleMouseEnterOnHoverHadler}></div>
    //   )}
    //   {!collapse && <>{SidebarContent}</>}
    //   <Drawer
    //     anchor="left"
    //     open={openDrawerSideBar}
    //     onClose={() => setOpenDrawerSideBar(false)}
    //     variant="temporary"
    //     BackdropProps={{ invisible: true }}
    //   >
    //     <PrimarySideBarWrapper
    //       style={{ width: '220px' }}
    //       theme={themes}
    //       collapsed={collapse}
    //       openDrawerSideBar={openDrawerSideBar}
    //       onMouseLeave={handleMouseLeaveOnDrawer}
    //       mode={mode}
    //     >
    //       {SidebarContent}
    //     </PrimarySideBarWrapper>
    //   </Drawer>
    // </PrimarySideBarWrapper>
    <>
    {isMobile ? (
      <Drawer
        variant="temporary"
        open={!collapse}
        onClose={() => setCollapse(true)}
        ModalProps={{ keepMounted: true }} // Better performance on mobile
        anchor="left"
      >
        <PrimarySideBarWrapper
          style={{ width: '220px' }}
          theme={themes}
          collapsed={collapse}
          openDrawerSideBar={openDrawerSideBar}
          onMouseLeave={handleMouseLeaveOnDrawer}
          mode={mode}
        >
          {SidebarContent}
        </PrimarySideBarWrapper>
      </Drawer>
    ) : (
      <PrimarySideBarWrapper collapsed={collapse} theme={themes} openDrawerSideBar={openDrawerSideBar} mode={mode}>
        {SidebarContent}
      </PrimarySideBarWrapper>
    )}
  </>
  );
};
