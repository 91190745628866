import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SideBarState = {
  collapsed: boolean;
};

const initialState: SideBarState = {
  collapsed: false,
};

const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    toggelSideBar: (state) => {
      state.collapsed = !state.collapsed;
    },
    setSideBar: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload;
    },
  },
});

export const { toggelSideBar, setSideBar } = sideBarSlice.actions;
export default sideBarSlice.reducer;
