import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const FltAdvanceMenuWrapper = styled.ul<{ theme: Theme }>`
  position: fixed;
  list-style-type: none;
  padding: 0;
  z-index: 1000;
  border: ${({ theme }) => `1px solid ${theme.border.secondary}`};
  border-radius: var(--spacing-0-75x, 6px);
  /* background: ${({ theme }) => theme.surface.background.secondary}; */
  background: ${({ theme }) => theme.surface.overlay.primary};
  box-shadow: ${({ theme }) => theme.boxshadow.medium};
  display: flex;
  flex-direction: column;
  gap: 2px;

  .dynamic-search-menu-container {
    margin-bottom: 6px;
    position: sticky;
    padding: 6px 6px 0 6px;
    top: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.surface.background.secondary};
  }
  .menu-item {
    display: flex;
    align-items: center;
    gap: var(--spacing-0-75x, 6px);
    /* padding: 6px; */
    margin: 0px 6px;
    align-self: stretch;
    border-radius: var(--spacing-0-75x, 6px);
    background: ${({ theme }) => theme.surface.button.tertiary.default};
    cursor: pointer;
  }
  .menu-item:hover {
    background: ${({ theme }) => theme.opacity.gray04};
  }
  .menu-item.focused {
    background: ${({ theme }) => theme.opacity.gray04};
    outline: none;
  }
  .menu-label {
    display: flex;
    padding: var(--spacing-0-50x, 4px) var(--spacing-0-75x, 6px);
    align-items: center;
    gap: var(--spacing-0-50x, 4px);
    align-self: stretch;
    border-radius: var(--spacing-0-50x, 4px);
    height: 24px;
    margin: 0px 6px;
  }
  .preselect-menu-item {
    display: flex;
    /* padding: 6px; */
    margin: 0px 6px;
    align-items: center;
    gap: var(--spacing-0-75x, 6px);
    align-self: stretch;
    border-radius: var(--spacing-0-75x, 6px);
    background: ${({ theme }) => theme.opacity.gray04};
    /* cursor: pointer; */
  }
  .no-data-menu-container {
    display: flex;
    padding: 8px;
    /* justify-content: center; */
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .menu-divider {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    height: 1px;
    background: ${({ theme }) => theme.border.tertiary};
    border: none;
    margin: 4px 0px;
    position: sticky;
    bottom: 0;
    z-index: 10;
  }
  .menu-action-btn {
    position: sticky;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: var(--spacing-0-50x, 4px);
    align-self: stretch;

    width: 100%;
    background: ${({ theme }) => theme.surface.button.secondary.default};
    border: none;
    cursor: pointer;
    padding: 10px 12px;
    border-top: 1px solid ${({ theme }) => theme.border.tertiary};
    margin-top: 6px;
  }
`;

export const Backdrop = styled.div<{ theme: Theme }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
`;
