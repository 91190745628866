interface Position {
  left: number;
  top: number;
}

export const getAnchorPosition = (rect: DOMRect, placement: string): Position => {
  let left = rect.left;
  let top = rect.bottom;

  switch (placement) {
    case 'top-start':
      // top = rect.top;
      left = rect.left; // Start from the anchor's top-right corner
      top = window.innerHeight - rect.top;
      break;
    case 'top':
      left += rect.width / 2;
      top = rect.top;
      break;
    case 'top-end':
      left = rect.right;
      top = rect.top;
      break;
    case 'left-start':
      left = rect.left;
      top = rect.top;
      break;
    case 'left':
      left = rect.left;
      top = rect.top + rect.height / 2;
      break;
    case 'left-end':
      left = rect.left;
      top = rect.bottom;
      break;
    case 'right-start':
      left = rect.right;
      top = rect.top;
      break;
    case 'right':
      left = rect.right;
      top =
        placement === 'right'
          ? rect.top + rect.height / 2
          : rect[placement === 'right-start' ? 'top' : 'bottom'];
      break;
    case 'right-end':
      left = rect.right;
      top = rect['bottom'];
      break;
    case 'bottom-start':
      left = rect.left;
      break;
    case 'bottom':
      left += rect.width / 2;
      // left += rect.width / 2 - menuWidth / 2; // Center the menu horizontally
      break;
    case 'bottom-end':
      left = window.innerWidth - rect.right;
      break;
    default:
      break;
  }

  return { left, top };
};

export const calculateAnchorPosition = (
  anchorEl: HTMLElement | null,
  placement: string,
  setPlacementApproach: (approach: string) => void,
  setMaxHeight: (height: number) => void,
  setAnchorPosition: (position: { X: number; Y: number }) => void,
  setVerticlePlacementApproach: (approach: string) => void,
  menuHeight? : number
): void => {
  if (anchorEl) {
    const rect = anchorEl.getBoundingClientRect();

    // Calculate maxHeight
    const spaceBelow = window.innerHeight - rect.bottom;
    // const spaceAbove = window.innerHeight - rect.top;
    const spaceAbove = rect.top;
    // const spaceAbove =  rect.top;

    // Dynamically switch placement based on available space
    if (placement.startsWith('bottom') && spaceBelow < 140) {  
      placement = 'top-start';
    } else if (placement.startsWith('top') && spaceAbove < 140) {//140 - minimum of height of menu
      placement = 'bottom-start';
    }

    const maxHeight = Math.min(
      placement.includes('top') ? spaceAbove : spaceBelow,
      menuHeight ? menuHeight : window.innerHeight * 0.9
    ); // Ensure maxHeight is within window height

    let { left, top } = getAnchorPosition(rect, placement);

    if (placement === 'bottom-end') {
      setPlacementApproach('right');
    } else if (placement === 'bottom-start') {
      setPlacementApproach('left');
    } else if (placement.includes('right')) {
      setPlacementApproach('right');
    } else if (placement.includes('left')) {
      setPlacementApproach('left');
    } else {
      setPlacementApproach('left');
    }

    if (placement === 'top-start') {
      setVerticlePlacementApproach('bottom');
    } else {
      setVerticlePlacementApproach('top');
    }

    setMaxHeight(menuHeight ? menuHeight : (maxHeight - 30));
    setAnchorPosition({ X: left, Y: top });
  }
};

