import { AppState } from '@src/app/rootReducer';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FltIcon from '../../fltIcon/fltIcon';
import FltText from '../../fltText/fltText';
import { FltTextInput } from '../../inputs/fltTextInput/fltTextInput';
import { Backdrop, FltAdvanceMenuWrapper } from './dropMenuStyle';
import { DropdownMenuProps, SelectOption } from './FltDynamicDropdownInterface';
import { calculateAnchorPosition } from './functions/menuAnchorPosition';

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  menuStyle,
  menuItemsData,
  anchorEl,
  open,
  onClose,
  placement = 'bottom-start',
  selectOptions,
  type = 'normal',
  preSelectedItems,
  placeholder,
  disableSearch = false,
  onClick,
  title,
  info = 'emailId',
  menuItemRenderer,
  footerActionLabel,
  onFooterActionClick,
  filterField,
  showItemCount = 5, // New prop for visible items count
  setSearchTerm,
  menuHeight,setOpen
}) => {
  const theme = useSelector((state: AppState) => state.theme.theme);
  const [anchorPosition, setAnchorPosition] = useState<{ X: number; Y: number }>({ X: 0, Y: 0 });
  const menuRef = useRef<HTMLUListElement>(null);
  const [searchText, setSearchText] = useState('');
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState<object>();
  const [placementApproach, setPlacementApproach] = useState<string | null>(null);
  const [verticlePlacementApproach, setVerticlePlacementApproach] = useState<string | null>(null);

  const [focusedIndex, setFocusedIndex] = useState(-1); // Track focused item
  const menuItemsRefs = useRef<(HTMLLIElement | null)[]>([]);

  useEffect(() => {
    if (preSelectedItems) {
      setSelectedItems(preSelectedItems);
    }
  }, [preSelectedItems]);

  const teamUserRelship = useSelector((state: AppState) => state.userRelship.arrayData);
  const users = useSelector((state: AppState) => state.users.arrayData);
  const searchInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (open && filterField && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [open, filterField]);
  useEffect(() => {
    const menuWidth = menuRef?.current?.offsetWidth;
    calculateAnchorPosition(
      anchorEl,
      placement,
      setPlacementApproach,
      setMaxHeight,
      setAnchorPosition,
      setVerticlePlacementApproach,
      menuHeight
    );
  }, [anchorEl, placement]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (open && menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
        setSearchText('');
        if (setSearchTerm) {
          setSearchTerm('');
        }
        setAnchorPosition({ X: 0, Y: 0 });
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [open]);

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    if (open) {
      document.body.style.overflowY = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflowY = '';
      document.body.style.paddingRight = '0';
    }

    return () => {
      document.body.style.overflowY = '';
      document.body.style.paddingRight = '0';
    };
  }, [open]);

  const handleMenuItemClick = (
    data: { data: object; otherData: object },
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    onClose();
    if (onClick) {
      onClick(data);
      setSearchText('');
    }
  };

  const filteredOptions = filterField
    ? selectOptions.filter((data: SelectOption) => {
        const fieldValue = data[filterField]?.toString();
        return searchText === ''
          ? true
          : fieldValue?.toLowerCase().includes(searchText.toLowerCase());
      })
    : selectOptions;

  // navigation

  useEffect(() => {
    if (open) {
      setFocusedIndex(-1); // Reset focus when dropdown opens
    }
  }, [open]);

  // const handleKeyDown = (event: KeyboardEvent) => {
  //   if (!open) return;

  //   const totalItems = filteredOptions.length;
  //   if (event.key === 'ArrowDown') {
  //     event.preventDefault();
  //     setFocusedIndex((prev) => (prev + 1) % totalItems);
  //   } else if (event.key === 'ArrowUp') {
  //     event.preventDefault();
  //     setFocusedIndex((prev) => (prev - 1 + totalItems) % totalItems);
  //   } else if (event.key === 'Enter' && focusedIndex !== -1) {
  //     event.preventDefault();
  //     const selectedOption = filteredOptions[focusedIndex];
  //     if (selectedOption && onClick) {
  //       onClick({
  //         data: selectedOption,
  //         otherData: menuItemsData?.otherData || {},
  //       });
  //       onClose();
  //       setSearchText('');
  //     }
  //   }
  // };
  const handleToggleDropdown = () => {
    console.log(open)
    if (open) {
      onClose(); // Close the dropdown if it's open
    } else {
     setOpen(true);
    }
  };
  
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      // event.preventDefault();
      console.group("tabbing")
      handleToggleDropdown();
    }
    if (!open) return;

    const totalItems = filteredOptions.length;
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedIndex((prev) => (prev + 1) % totalItems);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedIndex((prev) => (prev - 1 + totalItems) % totalItems);
    } else if (event.key === 'Enter' && focusedIndex !== -1) {
      event.preventDefault();
      const selectedOption = filteredOptions[focusedIndex];
      if (selectedOption && onClick) {
        onClick({
          data: selectedOption,
          otherData: menuItemsData?.otherData || {},
        });
        onClose();
        setSearchText('');
      }
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, focusedIndex, filteredOptions]);

  useEffect(() => {
    if (focusedIndex !== -1) {
      menuItemsRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex]);

  return (
    <>
      {open && anchorPosition.X != 0 && anchorPosition.Y != 0 && placementApproach && (
        <>
          <Backdrop theme={theme} />
          <FltAdvanceMenuWrapper
            theme={theme}
            ref={menuRef}
            style={{
              left: placementApproach == 'left' ? anchorPosition.X : '',
              right: placementApproach == 'right' ? anchorPosition.X : '',
              top: verticlePlacementApproach == 'top' ? anchorPosition.Y + 6 : '',
              bottom: verticlePlacementApproach == 'bottom' ? anchorPosition.Y + 6 : '',
              maxHeight: maxHeight || 'none', // Apply calculated maxHeight
              overflowY: 'auto', // Enable vertical scrolling if content exceeds maxHeight
              maxWidth: maxWidth !== undefined ? maxWidth : 'none',
              paddingTop: filterField ? '' : '6px',
              paddingBottom: footerActionLabel && onFooterActionClick ? '' : '6px',
              ...menuStyle,
            }}
          >
            {filterField && (
              <div className="dynamic-search-menu-container">
                <FltTextInput
                  ref={searchInputRef}
                  autoFocus={true}
                  inputType="text"
                  value={searchText}
                  placeholder={placeholder ? placeholder : 'Search...'}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (setSearchTerm) {
                      setSearchTerm(e.target.value);
                    }
                  }}
                  size="x-sm"
                  leftGlyph={
                    <FltIcon name="MagnifyingGlass" size={12} iconColor="content-tertiary" />
                  }
                  // appearance="no-border"
                />
              </div>
            )}
            {preSelectedItems && (
              <li className="preselect-menu-item">
                {menuItemRenderer ? (
                  menuItemRenderer(preSelectedItems)
                ) : (
                  <span className="menu-label">menu</span>
                )}
              </li>
            )}
            {preSelectedItems && <div className="menu-divider"></div>}
            {type == 'dynamic' &&
              selectOptions.length != 0 &&
              filteredOptions?.map((optionData: any, index: number) => {
                return (
                  <li
                    key={index}
                    ref={(el) => (menuItemsRefs.current[index] = el)}
                    tabIndex={-1}
                    onClick={(e) =>
                      handleMenuItemClick(
                        {
                          data: optionData,
                          otherData: menuItemsData?.otherData ? menuItemsData.otherData : {},
                        },
                        e
                      )
                    }
                    className={`menu-item ${focusedIndex === index ? 'focused' : ''}`}
                  >
                    {menuItemRenderer ? (
                      menuItemRenderer(optionData, searchText)
                    ) : (
                      <span className="menu-label">{optionData?.label}</span>
                    )}
                  </li>
                );
              })}
            {(selectOptions.length == 0 || filteredOptions.length == 0) && (
              <li className="no-data-menu-container">
                <FltText variant="label-small" textColor="content-tertiary">
                  No results found
                </FltText>
              </li>
            )}
            {footerActionLabel && onFooterActionClick && (
              <button className="menu-action-btn" onClick={onFooterActionClick}>
                <FltIcon name="Plus" size={14} />
                <FltText variant="heading-small">{footerActionLabel}</FltText>
              </button>
            )}
          </FltAdvanceMenuWrapper>
        </>
      )}
    </>
  );
};
